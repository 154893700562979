<template>
    <div class="home">
      <el-menu :default-active="formInline.status" class="el-menu-demo" mode="horizontal" @select="handleSelect2">
        <el-menu-item index="">全部</el-menu-item>
        <el-menu-item index="0">待处理</el-menu-item>
        <el-menu-item index="1">反馈成功</el-menu-item>
        <el-menu-item index="2">反馈失败</el-menu-item>
        <el-menu-item index="3">已删除</el-menu-item>
      </el-menu>
      <div class="pt20"></div>
        <el-form  :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item v-if="initdata.state != 2" label="审核时间">
                <el-date-picker
                    v-model="timevalue"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="提交时间">
                <el-date-picker
                    v-model="timevalue2"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>

          <el-form-item label="反馈对象类型">
            <el-select style="width:120px;"  v-model="formInline.type"  placeholder="请选择">
                <el-option label="全部" :value="''"></el-option>
                <el-option label="内容" :value="1"></el-option>
                <el-option label="评论" :value="2"></el-option>
            </el-select>
            </el-form-item>

          <el-form-item label="反馈问题">
            <el-select style="width:180px;"  v-model="formInline.report_type"  placeholder="请选择">
              <el-option v-for="rep in report" :key="rep.id" :label="rep.name" :value="rep.id"></el-option>
            </el-select>
            </el-form-item>

            <el-form-item style="font-weight: bold;" label="关键字">
            <el-input style="width:400px;" v-model="formInline.query" placeholder="反馈账号、订单编号、处理人、反馈人编号、内容编号"></el-input>
            <el-button style="margin-left:20px;" type="primary" @click="onSubmit('CHA')">查询</el-button>
            </el-form-item>
      </el-form> 
      
      <div style="text-align: left;margin-left:20px;" @change="onSubmit('CHA')" >
                <el-radio v-model="formInline.sort" label="asc" >升序</el-radio>
                <el-radio v-model="formInline.sort" label="desc" >降序</el-radio>
            </div>
      <div class="pt20"></div>

      <el-table
      v-loading="loading"
        :data="tableData"
        style="width: 100%">
         
       <el-table-column  width="1" align="center"/>
        <el-table-column label="序号" :index="typeIndex" type="index" show-overflow-tooltip width="50"></el-table-column>
         
        <el-table-column
          prop="report_no"
          label="反馈订单编号">
        </el-table-column>
        <el-table-column
          prop="report_user_count"
          label="反馈人数">
        </el-table-column>
        <el-table-column
          prop="type"
          label="反馈对象类型">
          <template slot-scope="scope">
        {{scope.row.type==1?'内容':''}}{{scope.row.type==2?'评论':''}}
          </template>
        </el-table-column>
        <el-table-column
          prop="created_at"
          label="提交时间">
        </el-table-column>
                
        <el-table-column
          prop="status" 
          label="处理状态">
          <template slot-scope="scope">
        {{scope.row.status==0?'未处理':''}}{{scope.row.status==1?'反馈成功':''}}{{scope.row.status==2?'反馈失败':''}}{{scope.row.status==3?'已删除':''}}
          </template>
        </el-table-column>
        <el-table-column
        v-if="initdata.state != 2"
          prop="operator_name"
          label="处理人">
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope">
        <el-button v-if="edit == 1 && scope.row.status != 2 && initdata.state == 1" @click="goedit(scope.row)" size="mini" type="info" plain>审核</el-button>
        <el-button v-if="detail == 1" @click="goedit2(scope.row)" size="mini" type="info" plain>查看</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pt20"></div>
        
<el-pagination
      @current-change="handleCurrentChange"
      current-page.sync="current_page"
      :page-size="per_page"
      layout="total,prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
import formatDate from '../../lib/dateFormat'

export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      loading:true,
      edit:location.edit,
      detail:location.detail,
      total:0,
      per_page: 0,
      current_page:1,
      tableData: [],
      formInline: {
         e_sdate:'',
         e_edate:'',
         t_sdate:'',
         t_edate:'',
         type:'',
         query:'',
         page:1,
         sort:'desc',
         status:'',
         state:1,
         report_type:-1,
      },
      timevalue:'',
      timevalue2:'',
      // 选中数组
      ghs: [],
      report:[],//反馈问题列表
     //选中的记录数量
     initdata:{
         e_sdate:'',
         e_edate:'',
         t_sdate:'',
         t_edate:'',
         type:'',
         query:'',
         page:1,
         sort:'desc',
         status:'',
         state:1,
         report_type:-1,
     },
     selectedNum:0,
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  watch:{
    $route: {
      handler: function(val){
        if(val.name == 'feedbacklist2'){
          this.handleSelect(2);
        }else{
          this.handleSelect(1);
        }
      }
    }
  },
  methods: {
      handleSelect2(key) {
        this.formInline.status = key;
        this.onSubmit('CHA');
      },
      onSubmit(CHA){
        let _this = this;
         _this.loading = true;
        if(CHA == 'CHA'){
          this.formInline.page = 1;
        }
        
          _this.formInline.e_sdate = '';
          _this.formInline.e_edate = '';
          _this.formInline.t_sdate = '';
          _this.formInline.t_edate = '';
          if(_this.timevalue){
            _this.formInline.e_sdate = formatDate(_this.timevalue[0]);
            _this.formInline.e_edate = formatDate(_this.timevalue[1]);
          }
          if(_this.timevalue2){
            _this.formInline.t_sdate = formatDate(_this.timevalue2[0]);
            _this.formInline.t_edate = formatDate(_this.timevalue2[1]);
          }
        axios.get(config.report,{params:this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          }) 
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      handleSelect(key) {
        this.formInline.state = key;
        this.onSubmit('CHA');
      },
    setpage(page){
      this.total = page.total;
      this.per_page = page.per_page;
      this.current_page = page.current_page;
    },typeIndex(index) {
      let _this = this; //处理分页数据的 index
      return (_this.formInline.page - 1) * 20 + index + 1;
    },
      handleCurrentChange(val) {
        this.formInline.page = val;
        this.onSubmit();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },
      goedit(obj){
        sessionStorage.setItem("edit", JSON.stringify(obj));
        sessionStorage.setItem("state", this.formInline.state);
        sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
       // this.$router.push('/feedback/feedbackedit');
        sessionStorage.setItem("pathname", location.pathname);
        window.open('/feedback/feedbackedit');
      },
      goedit2(obj){
        obj.look = true;
        sessionStorage.setItem("edit", JSON.stringify(obj));
        sessionStorage.setItem("state", this.formInline.state);
        sessionStorage.setItem("formInline", JSON.stringify(this.formInline));
       // this.$router.push('/feedback/feedbackedit');
        sessionStorage.setItem("pathname", location.pathname);
        window.open('/feedback/feedbackedit');
      },
    init(){
        let _this = this;
        let a = sessionStorage.getItem('formInline');
        this.formInline = a?JSON.parse(a):this.initdata;
        if(location.pathname == "/feedback/feedbacklist2"){
          this.initdata.state = 2;
          this.formInline.state = 2;
        }else{
          this.initdata.state = 1;
          this.formInline.state = 1;
        }
        axios.get(config.report,{params:this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        axios.get(config.selectreport,{})
        .then(function (response) {
            if(response.data.code == 200){
              _this.report = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    }
  },
  created(){
    
      this.init();
  }
}
</script>
<style scoped>
.left{
    width: 335px;
}
</style>